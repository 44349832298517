import { Icon } from '@chakra-ui/react';

const ShuttleIcon = () => (
  <Icon viewBox="0 0 40 40">
    <path
      d="M3.63636 8C1.61818 8 0 9.72067 0 11.8667V25.4H3.63636C3.63636 26.9383 4.21104 28.4135 5.23396 29.5012C6.25689 30.5889 7.64427 31.2 9.09091 31.2C10.5375 31.2 11.9249 30.5889 12.9479 29.5012C13.9708 28.4135 14.5455 26.9383 14.5455 25.4H25.4545C25.4545 26.9383 26.0292 28.4135 27.0521 29.5012C28.0751 30.5889 29.4625 31.2 30.9091 31.2C32.3557 31.2 33.7431 30.5889 34.766 29.5012C35.789 28.4135 36.3636 26.9383 36.3636 25.4H40V11.8667C40 9.72067 38.3818 8 36.3636 8H3.63636ZM2.72727 10.9H10V15.7333H2.72727V10.9ZM12.7273 10.9H20V15.7333H12.7273V10.9ZM22.7273 10.9H30V15.7333H22.7273V10.9ZM32.7273 10.9H37.2727V21.5333L32.7273 17.6667V10.9ZM9.09091 22.5C9.81423 22.5 10.5079 22.8055 11.0194 23.3494C11.5308 23.8932 11.8182 24.6309 11.8182 25.4C11.8182 26.1691 11.5308 26.9068 11.0194 27.4506C10.5079 27.9945 9.81423 28.3 9.09091 28.3C8.36759 28.3 7.6739 27.9945 7.16244 27.4506C6.65097 26.9068 6.36364 26.1691 6.36364 25.4C6.36364 24.6309 6.65097 23.8932 7.16244 23.3494C7.6739 22.8055 8.36759 22.5 9.09091 22.5M30.9091 22.5C31.6324 22.5 32.3261 22.8055 32.8376 23.3494C33.349 23.8932 33.6364 24.6309 33.6364 25.4C33.6364 26.1691 33.349 26.9068 32.8376 27.4506C32.3261 27.9945 31.6324 28.3 30.9091 28.3C30.1858 28.3 29.4921 27.9945 28.9806 27.4506C28.4692 26.9068 28.1818 26.1691 28.1818 25.4C28.1818 24.6309 28.4692 23.8932 28.9806 23.3494C29.4921 22.8055 30.1858 22.5 30.9091 22.5Z"
      fill="white"
    />
  </Icon>
);

export default ShuttleIcon;
