import { Icon } from '@chakra-ui/react';

const BusIcon = () => (
  <Icon viewBox="0 0 40 40">
    <path
      d="M36.3594 11.75H35.8125V8.46875C35.8125 5.40625 29.0313 3 20.5 3C11.9687 3 5.1875 5.40625 5.1875 8.46875V11.75H4.64062C3.73486 11.75 3 12.4842 3 13.3906V18.8594C3 19.7651 3.73486 20.5 4.64062 20.5H5.1875V31.4375C5.1875 32.6454 6.16709 33.625 7.375 33.625V35.8125C7.375 37.0204 8.35459 38 9.5625 38H11.75C12.9579 38 13.9375 37.0204 13.9375 35.8125V33.625H27.0625V35.8125C27.0625 37.0204 28.0421 38 29.25 38H31.4375C32.6454 38 33.625 37.0204 33.625 35.8125V33.625H34.0625C35.1562 33.625 35.8125 32.75 35.8125 31.875V20.5H36.3594C37.2651 20.5 38 19.7651 38 18.8594V13.3906C38 12.4842 37.2651 11.75 36.3594 11.75ZM10.6562 30.3438C9.44834 30.3438 8.46875 29.3642 8.46875 28.1562C8.46875 26.9483 9.44834 25.9688 10.6562 25.9688C11.8642 25.9688 12.8438 26.9483 12.8438 28.1562C12.8438 29.3642 11.8642 30.3438 10.6562 30.3438ZM11.75 22.6875C10.5421 22.6875 9.5625 21.7079 9.5625 20.5V11.75C9.5625 10.5421 10.5421 9.5625 11.75 9.5625H29.25C30.4579 9.5625 31.4375 10.5421 31.4375 11.75V20.5C31.4375 21.7079 30.4579 22.6875 29.25 22.6875H11.75ZM30.3438 30.3438C29.1358 30.3438 28.1562 29.3642 28.1562 28.1562C28.1562 26.9483 29.1358 25.9688 30.3438 25.9688C31.5517 25.9688 32.5312 26.9483 32.5312 28.1562C32.5312 29.3642 31.5517 30.3438 30.3438 30.3438Z"
      fill="white"
    />
  </Icon>
);

export default BusIcon;
